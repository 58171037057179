import { Grid, Spacer, Text } from 'dss-ui-library';
import {
  GetStaticPaths,
  GetStaticProps,
  GetStaticPropsContext,
  NextPage,
} from 'next';
import dynamic from 'next/dynamic';
import CombinedContent from '../components/CombinedContent';
import { Layout } from '../components/Layout';
import { MODULE_SPACING, REVALIDATON_DURATION } from '../constants';
import { useProcessedPage } from '../hooks/useProcessedPage';
import { useSalesAttribution } from '../hooks/useSalesAttribution';
import { Page, PageLayoutType, Sitemap } from '../interfaces';
import {
  createStaticPaths,
  fetchPage,
  fetchRedirects,
  fetchSitemap,
  findPageByCondition,
  getHybridContent,
  getNormalizedPath,
} from '../utils';
import { isNC } from '@ncs-frontend-monorepo/utils';
import CustomError from '../utils/ErrorClass';
import { Breadcrumb } from '@ncs-frontend-monorepo/content-library';

const FeedierModal = dynamic(
  () =>
    import('../components/Modals/Feedier').then(
      ({ FeedierModal }) => FeedierModal,
    ),
  {
    ssr: false,
  },
);

export interface DynamicPageProps {
  page: Page;
  sitemap?: Sitemap;
}

export const DynamicPage: NextPage<DynamicPageProps> = (props) => {
  const spacerSize = MODULE_SPACING;
  const { setVKZByQuery } = useSalesAttribution();
  const { page, isHybridPage } = useProcessedPage(props.page);

  setVKZByQuery();

  return (
    <Layout meta={page.meta} pageId={page.pageId} hasMaintenanceInfo>
      <Breadcrumb breadcrumbs={page.breadcrumbs} />
      <CombinedContent content={page.content} />
      {/* Only for debugging purposes, not part of the design */}
      {page.content.length === 0 && (
        <Grid>
          <Spacer y={spacerSize} block>
            <Text appearance="t2_2">Aktuelle Seite:</Text>
            <Text appearance="t2_2" color="blue" e2e="page-content-title">
              {` ${page?.meta?.title || page.pageId}`}
            </Text>
          </Spacer>
        </Grid>
      )}
      {isNC() && <FeedierModal isAllowedToOpen={!isHybridPage} />}
    </Layout>
  );
};

export default DynamicPage;

export const getStaticProps: GetStaticProps<DynamicPageProps> = async (
  context: GetStaticPropsContext,
) => {
  try {
    const subPath = getNormalizedPath(context, true);
    try {
      const { redirects = [] } = await fetchRedirects();
      const matchedRedirect = redirects.find((r) =>
        r.isRegexp
          ? new RegExp(r.sourcePath).test(subPath)
          : subPath === r.sourcePath,
      );

      if (matchedRedirect) {
        return {
          redirect: {
            destination: matchedRedirect.target,
            statusCode: matchedRedirect.targetStatuscode,
          },
          revalidate: REVALIDATON_DURATION,
        };
      }
    } catch (error) {
      console.warn(
        'An error occured while trying to fetch Redirects from API endpoint: ',
        error,
      );
    }

    const sitemap = await fetchSitemap();
    const path = subPath;
    const entry = findPageByCondition(sitemap.pageTree, (p) => p.path === path);

    if (!entry) {
      console.warn(
        `No entry found in sitemap for path ${path}, will fallback to 404`,
      );
      return {
        notFound: true,
        revalidate: REVALIDATON_DURATION,
      };
    }
    try {
      const page = await fetchPage(entry.pageId.toString());
      if (entry.layout === PageLayoutType.hybrid) {
        page.content = await getHybridContent(page.content, sitemap);
      }
      return {
        props: {
          page,
          sitemap,
        },
        revalidate: REVALIDATON_DURATION,
      };
    } catch (error) {
      console.warn(
        `Could not load page for path ${path}, will fallback to 404`,
      );
      return {
        notFound: true,
        revalidate: REVALIDATON_DURATION,
      };
    }
  } catch (error) {
    console.warn('ERROR: ', error);
    throw new CustomError(error.statusCode || 500);
  }
};

export const getStaticPaths: GetStaticPaths = async () => {
  let paths: string[] = [];
  if (process.env.ENABLE_PRERENDERING === 'true') {
    try {
      const sitemap = await fetchSitemap();
      paths = createStaticPaths(sitemap.pageTree);
    } catch (error) {
      // If no sitemap is available, skip pre-rendering instead of failing
      paths = [];
    }
  }
  return {
    paths,
    /* Don't show a loading screen, wait for re-generation instead */
    fallback: 'blocking',
  };
};
